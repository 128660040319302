// src/Card.js
import React, { useState } from "react";
import "../Styles/Card.css";
import search from "../images/search.png"; // Make sure to update the path to your logo
import { useNavigate } from "react-router-dom";

const Card = () => {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const navigate = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    if (!query) return;

    try {
      const response = await fetch(
        `https://rubyexplorer.com/searchdata/${query}`
      );
      if (!response.ok) {
        navigate(`/Invalidinput`);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("event", data.result);

      setResult(data.result);
      navigateByResult(data.result);
    } catch (error) {
      console.error("Error fetching search data:", error);
      setResult(""); // Reset result in case of error
      navigate(`/Invalidinput`);
    }
  };

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };

  const navigateByResult = (result) => {
    if (result === "transaction") {
      navigate(`/transaction/${query}`);
    } else if (result === "address") {
      navigate(`/address/${query}`);
    } else if (result === "block") {
      navigate(`/block/${query}`);
    } else {
      navigate(`/Invalidinput`);
    }
  };
  return (
    <div className="card-body">
      <div className="card-title">Ruby Blockchain Explorer</div>

      <form className="input-group" onSubmit={handleSearch}>
        <input
          type="text"
          className="form-control search-bar"
          placeholder="Search by address / txn hash / block / token..."
          style={{
            backgroundImage: `url(${search})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "10px center",
            width: "100%", // Adjust the width to fit the container
            paddingLeft: "30px", // Adjust the padding to make room for the icon
          }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </form>

      <div className="search-results">
        {query && (
          <p
            className="search-query"
            onClick={() => navigateByResult(result)}
            style={{ cursor: "pointer" }}
          >
            <div className="search-data-mobile">
              Address/hash
              <br />
              {shortenAddress(15, query)}
            </div>
            <div className="search-data">
              Address/hash
              <br />
              {query}
            </div>
          </p>
        )}
      </div>
    </div>
  );
};

export default Card;
