import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode'; // Corrected import for jwtDecode
import '../Styles/Dashboard.css';
import usdtlogo from '../images/usdt.png';
import rupeeindian from '../images/rupee-indian.png';
import logo from '../images/balancelogo.png';
import addresspng from "../images/address.png";
import transactionlogo from "../images/transaction.png";
import addresspng1 from "../images/address1.png";
import arrow from "../images/arrow.png";
import success from "../images/success.svg";
import QRCode from 'qrcode.react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Table, Row, Col } from "react-bootstrap";
import smartcontract from '../images/smart-contract.png';
import tokenlogo from '../images/tokenslogo.png';
import SendModal from './SendModal';

const Dashboard = () => {
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [walletData, setWalletData] = useState(null);
    const [rubyprice, setRubyprice] = useState('');
    const [activeTab, setActiveTab] = useState("Recent Transactions");
    const [transactions, setTransactions] = useState([]);
    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeAddress, setQRCodeAddress] = useState('');
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [recipientAddress, setRecipientAddress] = useState('');
    const [sendAmount, setSendAmount] = useState('');
    const [privateKey, setPrivateKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [showQRModal, setShowQRModal] = useState(false);
    const [tokentransactions, settokenTransactions] = useState([]);
    const [showtokenModal, setShowtokenModal] = useState(false);
    const [Contractaddress, setContractforsend] = useState('');
    const [Internaltransactions, setInternalTransactions] = useState([]);

    
    const redirectToLogin = useCallback(() => {
        navigate(`/Account`);
    }, [navigate]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setMessage("Address copied to clipboard!");
            setTimeout(() => setMessage(''), 2000);
        });
    };

    useEffect(() => {
        const fetchWalletData = async () => {
            try {
                const response = await fetch(
                    `https://rubyexplorer.com/getwalletdata/${email}`
                );
                const data = await response.json();
                if (data.status) {
                    setWalletData(data.result);
                } else {
                    redirectToLogin();
                }
            } catch (error) {
                console.error(error);
                redirectToLogin();
            }
        };
        if (email) {
            fetchWalletData();
        }
    }, [email, redirectToLogin]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            redirectToLogin();
        } else {
            const decodedToken = jwtDecode(token);
            if (decodedToken.exp * 1000 > Date.now()) {
                setEmail(decodedToken.email);
            } else {
                redirectToLogin();
            }
        }
    }, [redirectToLogin]);

    useEffect(() => {
        const fetchPrice = async () => {
            try {
                const response = await fetch('https://api.ctskola.io/api/getruby_price');
                const data = await response.json();
                setRubyprice(data.result);
            } catch (error) {
                console.error(error);
            }
        };
        fetchPrice();
    }, []);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                if (walletData && walletData.address) {
                    const response = await fetch(
                        `https://rubyexplorer.com/getuserTransction/${walletData.address}/${1}/${200}`
                    );
                    const data = await response.json();
                    setTransactions(data.result || []);
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (activeTab === "Recent Transactions" && walletData) {
            fetchTransactions();
        }
    }, [activeTab, walletData]);

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://rubyexplorer.com/getallTokens/${walletData.address}`);
                const data = await response.json();

                settokenTransactions(data.result ? data.result : []); // Corrected to map the array properly
            } catch (error) {
                console.error('Error fetching all transactions:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTransactions();
    }, [walletData?.address]);

 
    const shortenAddress = (length, address) => {
        return `${address.slice(0, length)}...${address.slice(-4)}`;
    };
    const handleaddressClick = (address) => {
        navigate(`/address/${address}`);
    };
    const formatTimeAgo = (date) => {
        const dates = new Date(date).getTime();
        const dateInSeconds = Math.floor(dates / 1000);
        let currentTime = Math.floor(Date.now() / 1000);
        let second = currentTime - dateInSeconds;
        if (second < 60) {
            return `${second}s ago`;
        } else if (second < 3600) {
            let minutes = Math.floor(second / 60);
            return `${minutes}m ago`;
        } else if (second < 86400) {
            let hours = Math.floor(second / 3600);
            return `${hours}h ago`;
        } else {
            let days = Math.floor(second / 86400);
            return `${days}d ago`;
        }
    };

    const handleReceiveClick = () => {
        if (walletData && walletData.address) {
            setQRCodeAddress(walletData.address);
            setShowQRCode(true);
            setShowQRModal(true);  // Show the QR code modal
        }
    };

    const handlecontractDetailClick = (contractAddress) => {
        navigate(`/TokenDetail/${contractAddress}`);
    };


    const copyToClipboard = (content) => {
        navigator.clipboard.writeText(content);
    };

    const trimValue = (value) => {
        let trimmedValue = Number(value).toFixed(5);
        trimmedValue = parseFloat(trimmedValue).toString();
        return trimmedValue;
    };

    const handleBlockClick = (blockNumber) => {
        navigate(`/Block/${blockNumber}`);
    };

    const handleSend = async () => {
        const url = `https://rubyexplorer.com/send/${privateKey}/${recipientAddress}/${sendAmount}`;
        setLoading(true);
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    setMessage("Transaction successful");
                    navigate(`/Dashboard`);
                } else {
                    setMessage(`Transaction failed`);
                }
            } else {
                const errorData = await response.json();
                setMessage(`Transaction failed`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            setLoading(false);
            setShowModal(false);  // Close the send modal
        }
    };
    const Contractforsend = (contract) => {
        setContractforsend(contract);
    };

    const handleClose = () => setShowtokenModal(false);
    const handleShow = () => setShowtokenModal(true);
        const handleTokenSend = async (body) => {
            console.log("body",body.address)
        const url = `https://rubyexplorer.com/sendtoken/${body.privateKey}/${body.address}/${Contractaddress}/${body.amount
        }`;
        setLoading(true);
        try {
            const response = await fetch(url);
            if (response.ok) {
                const data = await response.json();
                if (data.status) {
                    setMessage("Transaction successful");
                    navigate(`/Dashboard`);
                } else {
                    setMessage(`Transaction failed`);
                }
            } else {
                const errorData = await response.json();
                setMessage(`Transaction failed`);
            }
        } catch (error) {
            setMessage(`Error: ${error.message}`);
        } finally {
            setLoading(false);
            setShowModal(false);  // Close the send modal
        }

    };

    useEffect(() => {
        const fetchTransactions = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://rubyexplorer.com/getinternaltrx/${walletData.address}`);
                const data = await response.json();

                setInternalTransactions(data.result ? data.result : []); // Corrected to map the array properly
            } catch (error) {
                console.error('Error fetching all transactions:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchTransactions();
    }, [walletData?.address]);

    const handleTransactionClick = (hash) => {
        navigate(`/transaction/${hash}`);
      };

      const handleAlladdressClick = (address) => {
        navigate(`/address/${address}`);
      };

    return (
        <div>
            {message && <div className="alert alert-info mt-3">{message}</div>}
            <div className='dashboard-heading'>Overview</div>
            <div className="dashboard-card">
                <div className='dashboard-text'>Address</div>
                <div className='dashboard-address-container'>
                    <div
                        className='dashboard-address'
                        onClick={() => handleCopy(walletData?.address)}
                        title="Click to copy"
                    >
                        <strong>{walletData ? walletData.address : 'Loading...'}</strong>
                    </div>
                    <div
                        className='dashboard-address1'
                        onClick={() => handleCopy(walletData?.address)}
                        title="Click to copy"
                    >
                        <strong>{walletData ? shortenAddress(16, walletData.address) : 'Loading...'}</strong>
                    </div>
                    <button className="copy-button" onClick={() => handleCopy(walletData?.address)}>Copy</button>
                    <button className="receive-button" onClick={handleReceiveClick}>Receive</button>
                    <button className="send-button" onClick={() => setShowModal(true)}>Send</button>
                </div>
                <div className='dashboard-text'>Balance</div>
                <div className='dashboard-balance'>
                    <img src={logo} alt="usdtlogo" className="rubybalancelogo" />
                    <strong>{walletData ? trimValue(walletData.balance) : 'Loading...'}</strong>
                </div>
                {showQRCode && (
                    <Modal show={showQRModal} onHide={() => setShowQRModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>QR Code</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="qr-code-container">
                                <QRCode value={qrCodeAddress} size={256} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowQRModal(false)}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>

                )}
            </div>

            <div className='value-card'>
                <div className="dashboard-card-usdt">
                    <div className='dashboard-usdt-text'><strong>US Dollar</strong></div>
                    <div className='dashboard-usdt-balance'>
                        <img src={usdtlogo} alt="usdtlogo" className="usdtlogo" />
                        <strong>{walletData && rubyprice.usdt_price ? (rubyprice.usdt_price * walletData.balance).toFixed(2) : 0}</strong>
                    </div>
                </div>
                <div className="dashboard-card-inr">
                    <div className='dashboard-usdt-text'><strong>Indian Rupee</strong></div>
                    <div className='dashboard-usdt-balance'>
                        <img src={rupeeindian} alt="usdtlogo" className="inrlogo" />
                        <strong>{walletData && rubyprice.inr_price ? (rubyprice.inr_price * walletData.balance).toFixed(2) : 0}</strong>
                    </div>
                </div>
            </div>




            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Ruby</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div>Transaction initiated please wait a few seconds...</div>
                    ) : (
                        <>
                            <Form.Group controlId="recipientAddress">
                                <Form.Label>Recipient Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter recipient address"
                                    value={recipientAddress}
                                    onChange={(e) => setRecipientAddress(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="sendAmount">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter amount to send"
                                    value={sendAmount}
                                    onChange={(e) => setSendAmount(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group controlId="privateKey">
                                <Form.Label>Private Key</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your private key"
                                    value={privateKey}
                                    onChange={(e) => setPrivateKey(e.target.value)}
                                />
                            </Form.Group>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {!loading && (
                        <>
                            <Button variant="secondary" onClick={() => setShowModal(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={handleSend}>
                                Send
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>




            <div className="dashbord-tabs">
                {["Recent Transactions", "Tokens","Token Transactions"].map(
                    (tab) => (
                        <button
                            key={tab}
                            className={`dashbord-button ${activeTab === tab ? "active" : ""}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </button>
                    )
                )}
            </div>

            {activeTab === "Recent Transactions" && (
                <div>
                    <div className='Alltransaction-desktop'>

                        <Table>
                            <tbody>
                                {transactions.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>

                                            <div className='Alltransaction-hash'>

                                                {shortenAddress(12, transaction.hash)}
                                            </div>
                                            <div className='Alltransaction-time'>
                                                {formatTimeAgo(transaction.createdAt)}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='Alltransaction-type'>

                                                <div className='Alltransaction-status1'>
                                                    success
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="Alltransaction-twoaddress">
                                                <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                                                <div className="Alltransaction-address">
                                                    <span onClick={() => handleaddressClick(transaction.from)} style={{ cursor: "pointer" }}>
                                                        {shortenAddress(5, transaction.from)}
                                                    </span>
                                                    <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                                                </div>
                                                <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                                                <div className="Alltransaction-address1">
                                                    <span onClick={() => handleaddressClick(transaction.to)} style={{ cursor: "pointer" }}>
                                                        {shortenAddress(5, transaction?.to === '0' ? transaction.contract : transaction.to)}
                                                    </span>
                                                    <span onClick={() => copyToClipboard(transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span className='transfer-type'>
                                                {transaction?.transctiontype === 0
                                                    ? 'Coin transfer'
                                                    : transaction?.transctiontype === 1
                                                        ? 'Contract Create'
                                                        : transaction?.transctiontype === 2
                                                            ? 'Token transfer'
                                                            : 'Unknown'}
                                            </span>
                                        </td>
                                        <td>
                                            <div className='block-number'>{transaction.blockNumber}</div>
                                        </td>
                                        <td><div >
                                            <div className='Alltransaction-transfer'> {walletData?.address === transaction.from ? 'Send' : 'Received'}</div>

                                        </div>
                                        </td>

                                        <td>
                                            <div className="Alltransaction-amount">
                                                {trimValue(transaction.value)}<span className="Alltransaction-unit"> Value</span>
                                            </div>
                                            <div className="Alltransaction-fee">
                                                {trimValue(transaction.fee)}<span className="Alltransaction-unit"> Fee</span>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="Alltransaction-cards">
                        <Table table>
                            <tbody>
                                {transactions.map((transaction, index) => (
                                    <tr key={transaction.address} style={{ cursor: "pointer" }}>
                                        <td>
                                            <div>
                                                <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />

                                                <div className='Topaccounts-address-mobile'>{shortenAddress(15, transaction.hash)}</div>
                                                <div className='Topaccounts-time'>
                                                    {formatTimeAgo(transaction.createdAt)}
                                                </div>
                                                <div className='usersendrecived-transfer1'>
                                                    {transaction?.transctiontype === 0
                                                        ? 'Coin transfer'
                                                        : transaction?.transctiontype === 1
                                                            ? 'Contract Create'
                                                            : transaction?.transctiontype === 2
                                                                ? 'Token transfer'
                                                                : 'Unknown'}
                                                </div>
                                                <div >
                                                    <div className='usersendrecived-transfer'> {walletData?.address === transaction.from ? 'Send' : 'Received'}</div>

                                                </div>



                                                <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />
                                                <div className='Topaccounts-address-mobile'>{shortenAddress(5, transaction.from)}</div>
                                                <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                                                <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                                                <div className='Topaccounts-address1-mobile'>{shortenAddress(5, transaction.from)}</div>
                                                <br />

                                                <div className="Topaccounts-data-mobile">Value {transaction.value}</div>
                                                <br />
                                                <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>



                    </div>
                </div>
            )}
            {activeTab === "Tokens" && (
                <div>
                    <div className="dashbord-token-detail">
                        <Table>
                            <tbody>
                                {tokentransactions.map((transaction, index) => (
                                    <tr key={index}>
                                        <td>

                                        </td>
                                        <td>
                                            <div>
                                                <img src={smartcontract} alt="Transaction logo" className="Topaccounts-address-logo1" />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='dashboard-contractaddress' onClick={() => handlecontractDetailClick(transaction.contractAddress)} style={{ cursor: "pointer" }}>
                                                {transaction.contractAddress}
                                            </div>
                                        </td>

                                        <td>
                                            <div className='dashboard-contractbalance'>      <img src={logo} alt="Transaction logo" style={{ width: '16px', height: '16px', marginTop: '4px' }} />
                                                {trimValue(transaction.balance)}
                                            </div>
                                            <div className='dashboard-token-name'> <img src={tokenlogo} alt="Transaction logo" style={{ width: '16px', height: '16px', marginTop: '4px' }} />
                                                {transaction.name}
                                            </div>
                                            <div className='dashboard-token-transfer'>
                                          <span
                                                    className='dashboard-send'
                                                    onClick={() => { Contractforsend(transaction.contractAddress); handleShow(); }}
                                                >
                                                    Send
                                                </span>
                                                <span className='dashboard-receive' onClick={handleReceiveClick}>Receive</span>

                                            </div>
                                        </td>


                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div className="Topaccounts-cards">
                        <Table>
                            <tbody>
                                {tokentransactions.map((transaction, index) => (
                                    <tr key={transaction.contract_address}>
                                        <td>
                                            <div>
                                                <img src={smartcontract} alt="block logo" className="Topaccounts-address-logo-mobile" />
                                                <span></span>
                                                <div className='Topaccounts-address-mobile' onClick={() => handlecontractDetailClick(transaction.contractAddress)} style={{ cursor: "pointer" }}>{shortenAddress(25,transaction.contractAddress)}</div>
                                                <br />
                                                <div>
                                                    <img src={logo} alt="Transaction logo" className="Topaccounts-address-logo1" />
                                                </div>
                                                <div className="Topaccounts-address-mobile">Balance {trimValue(transaction.balance)}</div>
                                                <br />

                                                <div>
                                                    <img src={tokenlogo} alt="Transaction logo" className="Topaccounts-address-logo1" />
                                                </div>
                                                <div className="Topaccounts-address-mobile">Token Name {transaction.name}</div>
                                                <div className='dashboard-token-transfer'>
                                            <span className='dashboard-send' onClick={() => { Contractforsend(transaction.contract_address); handleShow(); }}>Send</span>
                                                <span className='dashboard-receive' onClick={handleReceiveClick}>Receive</span>

                                            </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div></div>

            )}
             {activeTab === "Token Transactions" && (
               <div className='Alltransaction-detail'>
                 <div>
                   <div className='Alltransaction-desktop'>
                     <Table>
                       <tbody>
                         {Internaltransactions.map((transaction, index) => (
                           <tr key={index}>
                             <td>
                               <div className='Alltransaction-hash' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>
                                 {shortenAddress(12, transaction.hash)}
                               </div>
                               <div className='Alltransaction-time'>
                                 {formatTimeAgo(transaction.createdAt)}
                               </div>
                             </td>
                             <td>
                               <div className='Alltransaction-type'>
                                 {/* <div className='Alltransaction-status'>
                               coin transfer
                             </div> */}
                                 <div className='Alltransaction-status1'>
                                   success
                                 </div>
                               </div>
                             </td>
                             <td>
                               <div className="Alltransaction-twoaddress">
                                 <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                                 <div className="Alltransaction-address">
                                   <span onClick={() => handleAlladdressClick(transaction.from)} style={{ cursor: "pointer" }}>
                                     {shortenAddress(5, transaction.from)}
                                   </span>
                                   <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                                 </div>
                                 <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                                 <div className="Alltransaction-address1">
                                   <span onClick={() => handleAlladdressClick(transaction?.to === '0' ? transaction.contract : transaction.to)} style={{ cursor: "pointer" }}>
       
                                   {shortenAddress(5, transaction?.to === '0' ? transaction.contract : transaction.to)}
                                </span>
                                   <span onClick={() => copyToClipboard(transaction?.to == '0' ? transaction.contract : transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                                 </div>
                               </div>
                             </td>
                             <td>
                               <div className='block-number'>{transaction.blockNumber}</div>
                             </td>
       
       
                             <td>
       
                             
       
                               <div className='Alltransaction-transfer'>
                             {transaction?.transctiontype === 0
                               ? 'Coin transfer'
                               : transaction?.transctiontype === 1
                                 ? 'Contract Create'
                                 : transaction?.transctiontype === 2
                                   ? 'Token transfer'
                                   : 'Unknown'}
                           </div>
                             </td>
                             <td>
                               <div className="Alltransaction-amount-fee"> <div className="Alltransaction-amount">
                                 {trimValue(transaction.value)}<span className="Alltransaction-unit"> Value</span>
                               </div>
                                 <div className="Alltransaction-fee">
                                   {trimValue(transaction.fee)}<span className="Alltransaction-unit"> Fee</span>
                                 </div></div>
       
                             </td>
                           </tr>
                         ))}
                       </tbody>
                     </Table>
                   </div>
                   <div className="Alltransaction-cards1">
                     <Table table>
                       <tbody>
                         {transactions.map((transaction, index) => (
                                               console.log("transaction",transaction.contract),
                           <tr key={transaction.address} style={{ cursor: "pointer" }}>
                             <td>
                               <div>
                                 <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />
       
                                 <div className='Topaccounts-address-mobile' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>{shortenAddress(15, transaction.hash)}</div>
                                 <div className='Topaccounts-time'>
                                   {formatTimeAgo(transaction.createdAt)}
                                 </div>
                                 <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />
                                 <div className='Topaccounts-address-mobile' onClick={() => handleAlladdressClick(transaction.from)} style={{ cursor: "pointer" }}>{shortenAddress(5, transaction.from)}</div>
                                 <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                                 <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                                 <div className='Topaccounts-address1-mobile' onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>
                                 {shortenAddress(5, transaction?.to === '0' ? transaction.contract : transaction.to)}
                                 </div>
                                 <br />
                                 <div className="Topaccounts-data-mobile">Value {transaction.value}</div>
                                 <br />
                                 <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                               </div>
                             </td>
                           </tr>
                         ))}
                       </tbody>
                     </Table>
                   </div>
                 </div>
             </div>

            )}
            <SendModal show={showtokenModal} handleClose={handleClose} handleTokenSend={handleTokenSend} />

        </div>
    );
};

export default Dashboard;
