import React, { useState } from "react";
import "../Styles/Searchbar.css"; // Import your CSS file for styling
import search from "../images/search.png"; // Make sure to update the path to your logo
import { useNavigate } from 'react-router-dom';
import addresspng from "../images/address.png";

const Search = () => {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const navigate = useNavigate();

  const handleSearch = async () => {
    if (!query) return;

    try {
      const response = await fetch(`https://rubyexplorer.com/searchdata/${query}`);
      if (!response.ok) {
        navigate(`/Invalidinput`);
        throw new Error("Network response was not ok");

      }
      const data = await response.json();
      console.log("event", data.result);

      setResult(data.result);
      navigateByResult(data.result);
    } catch (error) {
      navigate(`/Invalidinput`);
      console.error("Error fetching search data:", error);
    }
  };

  const navigateByResult = (result) => {
    if (result === 'transaction') {
      navigate(`/transaction/${query}`);
    } else if (result === 'address') {
      navigate(`/address/${query}`);
    } else if (result === 'block') {
      navigate(`/block/${query}`);
    } else {
      navigate(`/Invalidinput`);
    }
  };
  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };
  return (
    <div>
      <form className="search-txn" onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
        <input
          type="text"
          placeholder="Search by address / txn hash / block / token..."
          className="search-input"
          style={{
            backgroundImage: `url(${search})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "10px center",
            paddingLeft: "30px", // Adjust the padding to make room for the icon
            width: '100%', // Adjust the width to fit the container

          }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </form>
      <div className="search-results">
        {query && (
          <p className="search-query" onClick={handleSearch} style={{ cursor: "pointer", fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            <div className="search-data-mobile">
              Address/hash
              <br />
              {shortenAddress(15, query)}
            </div>
            <div className="search-data">
              Address/hash
              <br />
              {query}
            </div>


          </p>
        )}
      </div>
    </div>
  );
};

export default Search;
