import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Spinner } from "react-bootstrap";
import "../Styles/Alltransactions.css";
import "../Styles/Topaccounts.css";
import addresspng1 from '../images/address1.png';
import smartcontract from '../images/smart-contract.png';
import Search from "./Searchbar";

const VerifiedRuby = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [pagenumber, setPagenumber] = useState(1);
  const [pagelength] = useState(20); // Set a default page length

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://rubyexplorer.com/getContracts`);
        const data = await response.json();

        setTransactions(data.result ? data.result : []); // Corrected to map the array properly
      } catch (error) {
        console.error('Error fetching all transactions:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [pagenumber, pagelength]);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}......${address.slice(-4)}`;
  };

  const handleaddressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const handlecontractDetailClick = (contractAddress) => {
    navigate(`/contractDetail/${contractAddress}`);
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setPagenumber(newPage);
    }
  };

  return (
    <div className="Alltransaction-detail-container">
      <Search />
      <div className="Topaccounts-heading">Verified Ruby contracts</div>
      {loading ? (
        <div className="loading-container">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="Alltransaction-grid1">
            <div className="Alltransaction-grid-item">
              <div className="Alltransaction-grid-title">Total contracts</div>
              <div className="Alltransaction-grid-value-container">
                <div className="Alltransaction-grid-value">1</div>
                <div className="Alltransaction-grid-subtitle">(Total)</div>
              </div>
            </div>
            <div className="Alltransaction-grid-item">
              <div className="Alltransaction-grid-title">Verified contracts</div>
              <div className="Alltransaction-grid-value-container">
                <div className="Alltransaction-grid-value">1</div>
                <div className="Alltransaction-grid-subtitle">(Total)</div>
              </div>
            </div>
          </div>
          <div className="Topaccounts-detail">
            <Table>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={index}>
                    <td>
                      <div className='Topaccounts-index'>
                        {(pagenumber - 1) * pagelength + index + 1}
                      </div>
                    </td>
                    <td>
                      <div>
                        <img src={smartcontract} alt="Transaction logo" className="Topaccounts-address-logo1" />
                      </div>
                    </td>
                    <td>
                      <div className='Topaccounts-balance' onClick={() => handlecontractDetailClick(transaction.contract_address)} style={{ cursor: "pointer" }}>
                        {transaction.contract_address}
                      </div>
                    </td>
                    <td>
                      <div>
                        <img src={addresspng1} alt="Transaction logo" className="Topaccounts-address-logo1" />
                      </div>
                    </td>
                    <td>
                      <div className='Topaccounts-balance' onClick={() => handleaddressClick(transaction.owneraddress)} style={{ cursor: "pointer" }}>
                        {transaction.owneraddress}
                      </div>
                    </td>
                    <td>
                      <div className='Topaccounts-Percentage'>
                        {formatTimeAgo(transaction.createdAt)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div className="Topaccounts-cards">
            <Table>
              <tbody>
                {transactions.map((transaction, index) => (
                  <tr key={transaction.contract_address}>
                    <td>
                      <div>
                        <img src={smartcontract} alt="block logo" className="Topaccounts-address-logo-mobile" />
                        <span></span>
                        <div className='Topaccounts-address-mobile' onClick={() => handlecontractDetailClick(transaction.contract_address)} style={{ cursor: "pointer" }}>{shortenAddress(24, transaction.contract_address)}</div>
                        <br />
                        <div>
                          <img src={addresspng1} alt="Transaction logo" className="Topaccounts-address-logo1" />
                        </div>
                        <div className="Topaccounts-address-mobile" onClick={() => handleaddressClick(transaction.contract_address)} style={{ cursor: "pointer" }}>Owner {shortenAddress(17, transaction.owneraddress)}</div>
                        <br />
                        <div className="Topaccounts-data-mobile">Time {formatTimeAgo(transaction.createdAt)}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifiedRuby;
