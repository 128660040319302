import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "../Styles/Block.css"; // Import your CSS file for styling
import { Table, Row, Col } from "react-bootstrap";

import success from '../images/success.svg';
import transactionlogo from '../images/transaction.png';
import Block from '../images/Block.png';
import arrow from '../images/arrow.png';
import addresspng from '../images/address.png';
import addresspng1 from '../images/address1.png';
import info from '../images/info.png';

import Search from './Searchbar';

const Blockpage = () => {
  const { blockNumber } = useParams();
  const [blocks, setBlocks] = useState(null); // State to store block data

  const navigate = useNavigate();
  const [copyMessage, setCopyMessage] = useState("");
  const [activeTab, setActiveTab] = useState("Details");
  const [traceData, setTraceData] = useState(null);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await fetch(
          `https://rubyexplorer.com/getBlockNumberdata/${blockNumber}`
        );
        const data = await response.json();
        setBlocks(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlocks();
  }, [blockNumber]);

  // Render loading state if data is not yet available
  if (!blocks) {
    return <div>Loading...</div>;
  }

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - timestamp;

    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };


  const convertSecondsToIST = (timestamp) => {
    // Convert timestamp to milliseconds
    let milliseconds = timestamp * 1000;
    // Create a new Date object with the timestamp
    let date = new Date(milliseconds);
    // Get the IST time string
    let ISTTimeString = date.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
    return ISTTimeString;
  };
  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };
  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-4)}`;
  };

  const shortenHash = (address) => {
    return `${address.slice(0, 12)}..${address.slice(-4)}`;
  };
  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };


  const handleaddressClick = (address) => {
    navigate(`/address/${address}`);
  };
  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };
  return (
    <div className="transaction-detail-container">
      <Search />
      <div className="heading">Block #{blocks.blockdata?.number} </div>
      <span className="Validatedby">Validated by </span>

      <img
        src={addresspng1}
        alt="addresspng logo"
        className="blockminerlogo"
      />

      <div className="mineraddress">
        {blocks.blockdata?.miner}
      </div>
      <div className="mineraddress1">
        {shortenAddress(15, blocks.blockdata?.miner)}
      </div>

      <div className="block-tabs">
        {["Details", "Transactions", "Blob txns"].map((tab) => (
          <button
            key={tab}
            className={`tab-button ${activeTab === tab ? "active" : ""}`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>


      <div className="transaction-detail">

        {activeTab === "Details" && (
          <>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Block height</span>
            <span className="text-value">{blocks.blockdata?.number}</span>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Size</span>
            <span className="text-value">{blocks.blockdata?.size}</span>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Timestamp</span>
            <span className="text-value">{formatTimeAgoblock(blocks.blockdata?.timestamp)}</span>
            {/* <span className="timestamp-ist">| {convertSecondsToIST(blocks.blockdata?.timestamp)}</span> */}

            <span className="key">  <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Transactions</span>
            <span className="text-value" style={{ color: "#1381bd" }}>{blocks.transactionData.length} in this block</span>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Validated by</span>
            <span className="text-value" style={{ color: "#1381bd" }}>
              <img
                src={addresspng1}
                alt="miner logo"
                className="infologo"
              />
              {shortenAddress(20, blocks.blockdata?.miner)}

            </span>

            <hr style={{ margin: "10px 0", color: "lightblue" }} />{" "}
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Gas used</span>
            <span className="text-value">{blocks.blockdata?.gasUsed}</span>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />gasLimit</span>
            <span className="text-value">{blocks.blockdata?.gasLimit}</span>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />baseFeePerGas</span>
            <span className="text-value">{blocks.blockdata?.baseFeePerGas}</span>
            <hr style={{ margin: "10px 0", color: "lightblue" }} />{" "}
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Difficulty</span>
            <span className="text-value">{blocks.blockdata?.difficulty}</span>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Total difficulty</span>
            <span className="text-value">{blocks.blockdata?.totalDifficulty}</span>
            <hr style={{ margin: "10px 0", color: "lightblue" }} />{" "}
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Hash</span>
            <span className="text-value">{shortenAddress(28, blocks.blockdata?.hash)}</span>
            <span className="key" > <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Parent hash</span>
            <span className="text-value " style={{ color: "#1381bd" }}>{shortenAddress(28, blocks.blockdata?.parentHash)}</span>
            <span className="key"> <img
              src={info}
              alt="miner logo"
              className="infologo"
            />Nonce</span>
            <span className="text-value">{blocks.blockdata?.nonce}</span>


          </>

        )}

        {activeTab === "Transactions" && (
          <div>
            <div className='Alltransaction-desktop'>

              <Table>
                <tbody>
                  {blocks.transactionData.length > 0 ? (

                    blocks.transactionData.map((transaction, index) => (
                      <tr key={index}>
                        <td>
                          <div className='Alltransaction-hash'>
                            {shortenAddress(12, transaction.hash)}
                          </div>

                        </td>
                        <td>
                          <div className='Alltransaction-type'>
                            {/* <div className='Alltransaction-status'>
                         coin transfer
                       </div> */}
                            <div className='Alltransaction-status1'>
                              success
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='block-number'>{transaction.blockNumber}</div>
                        </td>
                        <td>
                          <div className='Alltransaction-transfer'>transfer</div>
                        </td>
                        <td>
                          <div className="Alltransaction-twoaddress">
                            <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                            <div className="Alltransaction-address">
                              <span onClick={() => handleaddressClick(transaction.from)} style={{ cursor: "pointer" }}>
                                {shortenAddress(5, transaction.from)}
                              </span>
                              <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                            </div>
                            <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                            <div className="Alltransaction-address1">
                              <span onClick={() => handleaddressClick(transaction.to)} style={{ cursor: "pointer" }}>
                                {shortenAddress(5, transaction.to)}
                              </span>
                              <span onClick={() => copyToClipboard(transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="Alltransaction-amount">
                            {trimValue(transaction.value)}<span className="Alltransaction-unit">Value</span>
                          </div>
                          <div className="Alltransaction-fee">
                            {trimValue(transaction.fee)}<span className="Alltransaction-unit">Fee</span>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="Internal-txns">
                      No transactions found
                    </div>
                  )}
                </tbody>
              </Table>
            </div>
            <div className="Alltransaction-cards">
              <Table table>
                <tbody>
                  {blocks.transactionData.map((transaction, index) => (
                    <tr key={transaction.address} style={{ cursor: "pointer" }}>
                      <td>
                        <div>
                          <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />

                          <div className='Topaccounts-address-mobile'>{shortenAddress(15, transaction.hash)}</div>

                          <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />
                          <div className='Topaccounts-address-mobile'>{shortenAddress(5, transaction.from)}</div>
                          <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                          <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                          <div className='Topaccounts-address1-mobile'>{shortenAddress(5, transaction.from)}</div>
                          <br />

                          <div className="Topaccounts-data-mobile">Value {transaction.value}</div>
                          <br />
                          <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {activeTab === "Blob txns" && (
          <div className="Internal-txns">
            No Blob txns
          </div>
        )}
      </div>
    </div>
  );
};

export default Blockpage;
