import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Row, Col } from "react-bootstrap";
import success from "../images/success.svg";
import transactionlogo from "../images/transaction.png";
import Block from "../images/Block.png";
import arrow from "../images/arrow.png";
import addresspng from "../images/address.png";
import addresspng1 from "../images/address1.png";

const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch(`https://rubyexplorer.com/getTransction`);
        const data = await response.json();
        setTransactions(data.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchBlocks = async () => {
      try {
        const response = await fetch("https://rubyexplorer.com/getBlockdatadata");
        const data = await response.json();
        setBlocks(data.result);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBlocks();
  }, []);

  const shortenAddress = (length, address) => {
    return `${address.slice(0, length)}...${address.slice(-6)}`;
  };

  const formatTimeAgo = (date) => {
    const dates = new Date(date).getTime();
    const dateInSeconds = Math.floor(dates / 1000);
    let currentTime = Math.floor(Date.now() / 1000);
    let second = currentTime - dateInSeconds;
    if (second < 60) {
      return `${second}s ago`;
    } else if (second < 3600) {
      let minutes = Math.floor(second / 60);
      return `${minutes}m ago`;
    } else if (second < 86400) {
      let hours = Math.floor(second / 3600);
      return `${hours}h ago`;
    } else {
      let days = Math.floor(second / 86400);
      return `${days}d ago`;
    }
  };

  const formatTimeAgoblock = (timestamp) => {
    let currentTime = Math.floor(Date.now() / 1000);
    let times = currentTime - timestamp;
    return `${times}s ago`;
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);
  };

  const handleTransactionClick = (hash) => {
    navigate(`/transaction/${hash}`);
  };

  const handleallTransactionClick = () => {
    navigate(`/Alltransactions`);
  };

  const handleBlockClick = (blockNumber) => {
    navigate(`/Block/${blockNumber}`);
  };

  const handleAllBlockClick = () => {
    navigate(`/AllBlock`);
  };

  const handleAlladdressClick = (address) => {
    navigate(`/address/${address}`);
  };

  const trimValue = (value) => {
    let trimmedValue = Number(value).toFixed(5);
    trimmedValue = parseFloat(trimmedValue).toString();
    return trimmedValue;
  };

  return (
    <Row className="transaction-container">
      <Col md={4}>
        <div className="block-cards">
          <h4 className="headingBlocks">Latest Blocks</h4>
          <Table table>
            <tbody>
              {blocks.map((block) => (
                <tr key={block.blockNumber} onClick={() => handleBlockClick(block.blockNumber)} style={{ cursor: "pointer" }}>
                  <td>
                    <div>
                      <img src={Block} alt="block logo" className="block-logo" />
                      <span className="block-blockNumber">{block.blockNumber}</span>
                      <br />
                      <div className="block-timestamp">{formatTimeAgoblock(block.timestamp)}</div>
                      <br />
                      <div className="block-Trx">Txn {block.Trx}</div>
                      <br />
                      <div className="blockHash-Validator">Validator:</div> <div className="blockhash">{shortenAddress(6, block.blockHash)}</div>
                      <div className="blockhash1">{shortenAddress(16, block.blockHash)}</div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="buttenallblock" onClick={handleAllBlockClick}>
            View all blocks
          </div>
          <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}

        </div>
      </Col>
      <Col md={7}>
        <h4 className="headingTransactions">Latest Transactions</h4>

        <div className="transaction-body">
          <Table table>
            <thead>
              <tr>

              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td>
                    <div className='transaction-status'>
                      {transaction?.transctiontype === 0
                        ? 'Coin transfer'
                        : transaction?.transctiontype === 1
                          ? 'Contract Create'
                          : transaction?.transctiontype === 2
                            ? 'Token transfer'
                            : 'Unknown'}
                    </div>
                    <div className='transaction-status1'>
                      success
                    </div>
                    <div className='transaction-hash' onClick={() => handleTransactionClick(transaction.hash)} style={{ cursor: "pointer" }}>
                      <img src={transactionlogo} alt="block logo" className="transactionlogo" />
                      {shortenAddress(6, transaction.hash)}
                    </div>


                  </td>

                  <td>

                    <span className='transaction-time'>
                      {formatTimeAgo(transaction.createdAt)}
                    </span>
                    
                  </td>
                  <td>
                    <div className='transactionblock-number'>{transaction.blockNumber}</div>

                  </td>

                  <td>
                    <div className="transaction-twoaddress">

                      <img src={addresspng} alt="Transaction logo" className="Alltransaction-address-logo" />{" "}
                      <div className="Alltransaction-address">
                        <span onClick={() => handleAlladdressClick(transaction.from)} style={{ cursor: "pointer" }}>
                          {/* <img src={arrow} alt="Transaction logo" className="Alltransaction-arrow" />{" "} */}

                          {shortenAddress(5, transaction.from)}
                        </span>
                        <span onClick={() => copyToClipboard(transaction.from)} className="copy-icon" title="Copy From">&#x2398;</span>
                      </div>
                      <img src={addresspng1} alt="Transaction logo" className="Alltransaction-address-logo1" />{" "}
                      <div className="Alltransaction-address1">
                        <span onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }}>
                          {shortenAddress(5, transaction?.to === '0' ? transaction.contract : transaction.to)}
                        </span>
                        <span onClick={() => copyToClipboard(transaction.to)} className="copy-icon" title="Copy To">&#x2398;</span>
                      </div>
                    </div>
                  </td>
                  <td><div>
                    <div className='transaction-value'> {trimValue(transaction.value)} Value</div>
                    <div className='transaction-fee'> {trimValue(transaction.fee)} Fee</div>
                  </div></td>
                 
                  <td>

                  </td>
                </tr>
              ))}
              <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}

              <div className="alltransactions" onClick={handleallTransactionClick}>
                View all transactions
              </div>
            </tbody>
          </Table>

        </div>

        <div className="Topaccounts-cards">
          <Table table>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={transaction.address} style={{ cursor: "pointer" }}>
                  <td>
                    <div>
                      <img src={transactionlogo} alt="block logo" className="Topaccounts-address-logo-mobile" />

                      <div className='Topaccounts-address-mobile ' onClick={() => handleTransactionClick(transaction.hash)}>{shortenAddress(15, transaction.hash)}
                      </div>

                      <div className='Topaccounts-time'>
                        {formatTimeAgo(transaction.createdAt)}
                      </div>
                      <img src={addresspng} alt="block logo" className="Topaccounts-address-logo-mobile" />

                      <div className='Topaccounts-address-mobile' onClick={() => handleAlladdressClick(transaction.from)} style={{ cursor: "pointer" }}>{shortenAddress(5, transaction.from)}

                      </div>

                      <img src={arrow} alt="Transaction logo" className="Topaccounts-arror-logo1" />{" "}
                      <img src={addresspng1} alt="block logo" className="Topaccounts-address1-logo-mobile" />
                      <div className='Topaccounts-address1-mobile' onClick={() => handleAlladdressClick(transaction.to)} style={{ cursor: "pointer" }} >{shortenAddress(5, transaction.to)}

                      </div>
                      <div onClick={() => copyToClipboard(transaction.from)} className="from-icon" title="Copy From">&#x2398;</div>
                      <div onClick={() => copyToClipboard(transaction.to)} className="to-icon" title="Copy From">&#x2398;</div>
                      <br />

                      <div className="Topaccounts-data-mobile">Value {transaction.value}</div>
                      <br />
                      <div className="Topaccounts-data-mobile">Fee {transaction.fee}</div>
                    </div>
                  </td>
                  <hr style={{ margin: "20px 0", color: "lightblue" }} />{" "}


                </tr>

              ))}
              <div className="alltransactions" onClick={handleallTransactionClick}>
                View all transactions
              </div>
            </tbody>
          </Table>



        </div>
      </Col>
    </Row>
  );
};

export default TransactionTable;
